@postcss-normalize;

@font-face {
  font-family: Godwit;
  src: url(/static/fonts/GodwitDisplayVariable-VF.woff2)
    format('woff2-variations');
  font-weight: 100 900;
  font-size: 16pt 48pt;
  line-height: 20pt 56pt;
}

@font-face {
  font-family: Godwit;
  src: url(/static/fonts/GodwitTextVariable-VF.woff2) format('woff2-variations');
  font-weight: 100 900;
  font-size: 12pt 16pt;
  line-height: 12pt 20pt;
}

body {
  font-family: Godwit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

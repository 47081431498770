.slider:global(.rc-slider) {
  height: 16px;
  padding: 6px 0;
  margin-left: 8px;
  margin-right: 8px;
  width: unset;
}

.slider :global(.rc-slider-track) {
  background: #0194f3;
}

.slider :global(.rc-slider-handle) {
  border: 1px solid #0194f3;
  height: 16px;
  margin-top: -6px;
  width: 16px;
}

.slider :global(.rc-slider-handle):active {
  box-shadow: 0 0 5px #0194f3;
}

.slider :global(.rc-slider-handle::before) {
  background: #0194f3;
  border-radius: 50%;
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  transform: scale(0.714285714);
}
